
import Vue from "vue"
import ClickOutside from "vue-click-outside"
import { getImagesAsset, getDemoAsset, checkDomain } from "@/config/util"
import subscriptionStatusMixin from "@/mixins/subscriptionStatusMixin"
import SvgIcon from "@/components/base/SvgIcon/SvgIcon.vue"
import BlockPopupInvite from "@/components/blocks/BlockPopup/BlockPopupInvite.vue"
import { mapState } from "vuex"
import notificationSoundFile from "@/assets/sound/new-message.mp3"

interface Data {
  placeholderImage: string
  notificationImage: string
  notificationNum: number
  active: boolean
  pageCheck: boolean
  showPopup: boolean
  ExplanationVideoPopupActive: boolean
  prevNotificationCount: number | null
  notificationSound: HTMLAudioElement | null
  initialLoadComplete: boolean
  showWarningAlert: boolean
  showDangerAlert: boolean
  showNotificationModal: boolean
}

export default Vue.extend({
  name: "NotificationComponent",

  components: {
    SvgIcon,
    BlockPopupInvite,
  },

  directives: {
    ClickOutside,
  },
  mixins: [subscriptionStatusMixin],

  data(): Data {
    return {
      placeholderImage: getImagesAsset("placeholders/user_placeholder.png"),
      notificationImage: getDemoAsset("profile/notification.png"),
      notificationNum: 17,
      active: false,
      pageCheck: false,
      showPopup: false,
      ExplanationVideoPopupActive: false,
      prevNotificationCount: null,
      notificationSound: null,
      initialLoadComplete: false,
      showWarningAlert: false,
      showDangerAlert: false,
      showNotificationModal: false,
    }
  },

  computed: {
    isTrialing() {
      return this.$store.getters.subscriptionIsTrial
    },
    loggedIn() {
      return this.$store.getters.getLoggedIn
    },
    user() {
      return this.$store.getters.getUser
    },
    image() {
      return this.$store.getters.getProfileImage
    },
    subscription() {
      return this.$store.state.Broker.subscription
    },
    subscriptionName() {
      let plan = this.$store.state.Broker.subscription
      if (this.isActiveSubscription(plan?.status)) {
        return plan?.name
      } else if (this.isTrialingSubscription(plan?.status)) {
        return `${plan?.name} - Free`
      } else {
        return "Free tier"
      }
    },
    daysRemaining() {
      const today = new Date()
      const endsAt = new Date(this.subscription?.ends_at)
      const diff = endsAt.getTime() - today.getTime()
      const days = Math.round(diff / (1000 * 3600 * 24))
      return days > 1 ? `${days} days` : `${days} day`
    },
    explanationVideoFromhamburgerMenuClicked() {
      return this.$store.getters.getExplanationVideoClicked
    },
    userRoleSpecificVideoLink() {
      return this.$store.getters.getUser.role === "broker"
        ? "https://financelobby-production.s3.amazonaws.com/assets/Broker.mp4"
        : "https://financelobby-production.s3.amazonaws.com/assets/FinanceLobby051222.mp4"
    },

    userRoleSpecificMessage() {
      const responseCategory = this.getAvarageResponseTime.label
      if (this.$store.getters.getUser.role === "broker") {
        if (responseCategory === "A few business days") {
          return `
            <h3 class="text-[14px] text-[#92400E] font-semibold">Responsiveness: Medium </h3>
            <p class="text-[14px] text-[#B45309] font-medium">Your responsiveness has dropped, which will reduce deal visibility and lender interest. Prompt replies help improve your overall response time, so be sure to respond to messages quickly.</p>
          `
        }
        if (responseCategory === "A week or more") {
          return `
            <h3 class="text-[14px] text-[#92400E] font-semibold">Responsiveness: Low</h3>
            <p class="text-[14px] text-[#B45309] font-medium">Low responsiveness will negatively affect your deal visibility and lead lenders to prioritize more responsive brokers. Prompt replies help improve your overall response time, so be sure to respond to messages quickly.</p>
          `
        }
      }

      if (this.$store.getters.getUser.role === "lender") {
        if (responseCategory === "A few business days") {
          return `
            <h3 class="text-[14px] text-[#92400E] font-semibold">Responsiveness: Medium </h3>
            <p class="text-[14px] text-[#B45309] font-medium">Your responsiveness has dropped, which will reduce broker interest and the likelihood of your quotes being accepted. Prompt replies help improve your overall response time, so be sure to respond to messages quickly.</p>
          `
        }
        if (responseCategory === "A week or more") {
          return `
            <h3 class="text-[14px] text-[#92400E] font-semibold">Responsiveness: Low </h3>
            <p class="text-[14px] text-[#B45309] font-medium">Low responsiveness will negatively affect broker interest and reduce the likelihood of your quotes being accepted. Prompt replies help improve your overall response time, so be sure to respond to messages quickly.</p>
          `
        }
      }
      return ""
    },

    userRoleSpecificMessageVariant() {
      const responseCategory = this.getAvarageResponseTime.label
      if (responseCategory === "A few business days") {
        return "warning"
      }
      if (responseCategory === "A week or more") {
        return "warning"
      }
      return ""
    },

    ...mapState({
      non_beta_user: (state) => state.User.non_beta_user,
      unseenMessages: (state) => state.Chat.chat.unseenMessages,
    }),
    getPlayNotificationSound() {
      return this.$store.getters.getPlayNotificationSound
    },
    getAvarageResponseTime() {
      const seconds = this.$store.getters.getAvarageResponseTime
      if (seconds == null || seconds === 0) {
        return { time: "No response time recorded", label: "Not available" }
      }

      const minutes = Math.floor(seconds / 60)
      const hours = Math.floor(minutes / 60)
      const days = Math.floor(hours / 24)

      let time, label
      if (days > 0) {
        time = `${days} day${days > 1 ? "s" : ""} ago`
      } else if (hours > 0) {
        time = `${hours} hour${hours > 1 ? "s" : ""} ago`
      } else if (minutes > 0) {
        time = `${minutes} minute${minutes > 1 ? "s" : ""} ago`
      } else {
        time = `a few seconds ago`
      }

      if (seconds > 86400) {
        label = "A week or more"
      } else if (seconds > 28800) {
        label = "A few business days"
      } else if (seconds > 14400) {
        label = "A business day"
      } else {
        label = "A few business hours"
      }

      return { time, label }
    },

    responseLabelClass() {
      const label = this.getAvarageResponseTime.label
      switch (label) {
        case "A few business hours":
        case "A business day":
          return "response-label-green"
        case "A few business days":
          return "response-label-yellow"
        case "A week or more":
          return "response-label-red"
        default:
          return ""
      }
    },

    notificationsGranted(): boolean {
      return Notification.permission === "granted"
    },
  },

  watch: {
    getPlayNotificationSound(val: boolean) {
      if (val) {
        this.$store.dispatch("playNotificationSound", false)
        this.playNotificationSound()
      }
    },
    unseenMessages: {
      handler(newVal: { count: number }) {
        if (!this.initialLoadComplete) {
          // Do not play sound or send notification on initial load
        } else if (newVal.count > (this.prevNotificationCount || 0)) {
          this.playNotificationSound()
          this.sendBrowserNotification("New Message", {
            body: `You have ${newVal.count} new message(s).`,
            icon: this.notificationImage,
          })
        }
        this.prevNotificationCount = newVal.count
        this.updateDocumentTitle(newVal)
      },
      deep: true,
      immediate: true,
    },
  },

  mounted() {
    if (this.loggedIn) {
      this.$store.dispatch("getUnseenMessages").then(() => {
        this.prevNotificationCount = this.unseenMessages.count
        this.initialLoadComplete = true
      })

      const auth = JSON.parse(localStorage.getItem("auth") || "{}")
      this.loadAvarageResponse(auth?.user?.id)

      const firstTimeLogin = localStorage.getItem("second_time_login")
      if (!firstTimeLogin) {
        document.cookie.split(";").forEach((cookie) => {
          const name = cookie.split("=")[0].trim()
          document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`
        })
        localStorage.clear()
        localStorage.setItem("second_time_login", "true")
        window.location.assign("/login")
      }

      // Only show the notification modal if permission isn't granted
      if (Notification.permission !== "granted") {
        this.checkNotificationModalStatus()
      }
    }

    this.showDangerAlert = !localStorage.getItem("dismissedDangerAlert")
    this.showWarningAlert = !localStorage.getItem("dismissedWarningAlert")

    // Initialize notification sound
    this.notificationSound = new Audio(notificationSoundFile)
    this.notificationSound.preload = "auto"

    this.$root.$on("logout-beta", this.logout)
  },

  beforeDestroy() {
    this.$root.$off("logout-beta", this.logout)
  },

  methods: {
    async loadAvarageResponse(id) {
      try {
        await this.$store.dispatch("useAvarageResponseTime", id)
      } catch (error) {
        console.error("Error loading average response time:", error)
      }
    },

    dismissAlert(type) {
      if (type === "danger") {
        this.showDangerAlert = false
        localStorage.setItem("dismissedDangerAlert", "true")
      } else if (type === "warning") {
        this.showWarningAlert = false
        localStorage.setItem("dismissedWarningAlert", "true")
      }
    },

    async handleManageMySubscription() {
      try {
        const response = await this.$store.dispatch("getSparkUrl", {
          return_url: `${window.location.origin}${window.location.pathname}`,
        })
        window.location.href = response.data.data.sparkLogin.success
      } catch (error) {
        console.error("Error managing subscription:", error)
      }
    },

    playNotificationSound() {
      const currentPath = this.$route.path
      const excludedPaths = ["/lender-messages", "/broker-messages"]
      if (!this.active && !excludedPaths.includes(currentPath) && this.notificationSound) {
        this.notificationSound.play().catch((error) => console.error("Sound play failed:", error))
      }
    },

    clickOutside(event: Event) {
      if (this.$refs.video && !(this.$refs.video as HTMLElement).contains(event.target as Node)) {
        this.ExplanationVideoPopupActive = false
      }
    },

    ExplanationVideo() {
      this.ExplanationVideoPopupActive = true
      this.$store.dispatch("explanationVideoFromhamburgerMenuClicked", false)
    },

    popupClose() {
      this.ExplanationVideoPopupActive = false
      this.$store.dispatch("explanationVideoFromhamburgerMenuClicked", false)
    },

    logout() {
      this.$store.dispatch("logout").then(() => {
        if (process.env.VUE_APP_GTM_ENVIRONMENT === "production") {
          const auth = JSON.parse(localStorage.getItem("auth") || "{}")
          window["dataLayer"] = window["dataLayer"] || []
          window["dataLayer"].push({
            event: "logout",
            userId: auth?.user?.id,
          })
        }

        localStorage.removeItem("auth")
        localStorage.removeItem("dismissedWarningAlert")
        localStorage.removeItem("dismissedDangerAlert")
        localStorage.removeItem("chatbot")
        localStorage.removeItem("ai-state")
        window.location.href = "/login"
      })
    },

    hide() {
      this.active = false
    },

    clearToken() {
      if (this.$router.currentRoute.path === "/sign-up/lender/2") {
        const token = localStorage.getItem("tempToken")
        if (token !== null) {
          localStorage.removeItem("tempToken")
        }
      }
    },

    openPopup() {
      this.showPopup = true
    },
    closePopup() {
      this.showPopup = false
    },
    goTo() {
      if (this.$route.path !== "/profile") {
        this.$router.push({ path: "/profile" })
      }
    },

    logoChange(): boolean {
      return checkDomain()
    },

    updateDocumentTitle(unseenMessages: { count: number }) {
      const count = unseenMessages?.count || 0
      if (count > 0) {
        document.title = `(${count}) New Messages`
        this.changeFavicon("/faviconnotification.ico")
      } else {
        document.title = "Finance Lobby - Meet Your Perfect Deal"
        this.changeFavicon("/favicon.ico")
      }
    },

    changeFavicon(href: string) {
      let link = document.querySelector("link[rel*='icon']") as HTMLLinkElement
      if (link) {
        link.href = `${href}?v=${new Date().getTime()}`
      } else {
        link = document.createElement("link")
        link.rel = "shortcut icon"
        link.href = `${href}?v=${new Date().getTime()}`
        document.head.appendChild(link)
      }
    },

    // eslint-disable-next-line no-undef
    sendBrowserNotification(title: string, options?: NotificationOptions) {
      if (Notification.permission === "granted") {
        const notification = new Notification(title, options)
        notification.onclick = () => {
          window.focus()
          this.$router.push("/chat")
          notification.close()
        }
      }
    },

    checkNotificationModalStatus() {
      const lastShown = localStorage.getItem("notificationModalLastShown")
      const now = Date.now()
      const oneWeek = 7 * 24 * 60 * 60 * 1000

      // If never shown before or shown more than a week ago, show the modal
      if (!lastShown || now - parseInt(lastShown, 10) > oneWeek) {
        this.showNotificationModal = true
      }
    },

    async enableNotifications() {
      // Called when user clicks "Enable Notifications" button in the modal
      try {
        const permission = await Notification.requestPermission()
        console.log("Notification requestPermission result:", permission, Notification.permission)
        if (Notification.permission === "granted") {
          // Notifications are enabled. Remove dismissal so we don't show modal again.
          localStorage.removeItem("notificationModalLastShown")
        } else {
          // User denied or closed prompt without allowing
          this.recordModalDismissal()
        }
      } catch (error) {
        console.error("Error requesting notification permission:", error)
        this.recordModalDismissal()
      } finally {
        this.showNotificationModal = false
      }
    },

    dismissModal() {
      // User chose "Not Now," record time and hide
      this.recordModalDismissal()
      this.showNotificationModal = false
    },

    recordModalDismissal() {
      localStorage.setItem("notificationModalLastShown", Date.now().toString())
    },
  },
})
